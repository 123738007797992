(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("faux-hypergrid/src/lib/Canvas"), require("@finos/perspective-viewer/dist/esm/custom_styles"), require("faux-hypergrid/src/cellEditors/Textfield"), require("core-js/modules/web.dom-collections.iterator"), require("faux-hypergrid/src/behaviors/Behavior"), require("lodash/isEqual"), require("faux-hypergrid/src/cellRenderers"), require("@finos/perspective-viewer/dist/esm/utils.js"), require("lodash/cloneDeep"), require("@finos/perspective/dist/esm/config"), require("faux-hypergrid"), require("faux-hypergrid/src/Base"), require("fin-hypergrid-grouped-header-plugin"), require("rectangular"), require("superscript-number"), require("@finos/perspective/dist/esm/config/index.js"), require("datasaur-local"), require("core-js/modules/es.string.replace"));
	else if(typeof define === 'function' && define.amd)
		define(["faux-hypergrid/src/lib/Canvas", "@finos/perspective-viewer/dist/esm/custom_styles", "faux-hypergrid/src/cellEditors/Textfield", "core-js/modules/web.dom-collections.iterator", "faux-hypergrid/src/behaviors/Behavior", "lodash/isEqual", "faux-hypergrid/src/cellRenderers", "@finos/perspective-viewer/dist/esm/utils.js", "lodash/cloneDeep", "@finos/perspective/dist/esm/config", "faux-hypergrid", "faux-hypergrid/src/Base", "fin-hypergrid-grouped-header-plugin", "rectangular", "superscript-number", "@finos/perspective/dist/esm/config/index.js", "datasaur-local", "core-js/modules/es.string.replace"], factory);
	else if(typeof exports === 'object')
		exports["perspective-viewer-hypergrid"] = factory(require("faux-hypergrid/src/lib/Canvas"), require("@finos/perspective-viewer/dist/esm/custom_styles"), require("faux-hypergrid/src/cellEditors/Textfield"), require("core-js/modules/web.dom-collections.iterator"), require("faux-hypergrid/src/behaviors/Behavior"), require("lodash/isEqual"), require("faux-hypergrid/src/cellRenderers"), require("@finos/perspective-viewer/dist/esm/utils.js"), require("lodash/cloneDeep"), require("@finos/perspective/dist/esm/config"), require("faux-hypergrid"), require("faux-hypergrid/src/Base"), require("fin-hypergrid-grouped-header-plugin"), require("rectangular"), require("superscript-number"), require("@finos/perspective/dist/esm/config/index.js"), require("datasaur-local"), require("core-js/modules/es.string.replace"));
	else
		root["perspective-viewer-hypergrid"] = factory(root["faux-hypergrid/src/lib/Canvas"], root["@finos/perspective-viewer/dist/esm/custom_styles"], root["faux-hypergrid/src/cellEditors/Textfield"], root["core-js/modules/web.dom-collections.iterator"], root["faux-hypergrid/src/behaviors/Behavior"], root["lodash/isEqual"], root["faux-hypergrid/src/cellRenderers"], root["@finos/perspective-viewer/dist/esm/utils.js"], root["lodash/cloneDeep"], root["@finos/perspective/dist/esm/config"], root["faux-hypergrid"], root["faux-hypergrid/src/Base"], root["fin-hypergrid-grouped-header-plugin"], root["rectangular"], root["superscript-number"], root["@finos/perspective/dist/esm/config/index.js"], root["datasaur-local"], root["core-js/modules/es.string.replace"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__5__, __WEBPACK_EXTERNAL_MODULE__6__, __WEBPACK_EXTERNAL_MODULE__7__, __WEBPACK_EXTERNAL_MODULE__8__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__10__, __WEBPACK_EXTERNAL_MODULE__11__, __WEBPACK_EXTERNAL_MODULE__12__, __WEBPACK_EXTERNAL_MODULE__13__, __WEBPACK_EXTERNAL_MODULE__14__, __WEBPACK_EXTERNAL_MODULE__15__, __WEBPACK_EXTERNAL_MODULE__16__, __WEBPACK_EXTERNAL_MODULE__20__, __WEBPACK_EXTERNAL_MODULE__22__) {
return 